import BuyButton from "./BuyButton"

const Footer = () => {
    return (
        <div className="bg-background-radial-gradient pb-6">
            <div className="border-t border-[#E9FF00]  flex justify-center items-center gap-x-3 md:gap-x-10 text-white pt-6 md:pt-12 ">
                <a
                    href="https://twitter.com/funs_ai"
                    target="_blank"
                    className=" flex items-center gap-x-1 md:gap-x-3 text-12-20  font-medium duration-300 hover:text-[#E9FF00]"
                >
                    <img src="/assets/twitter.svg" alt="" className="w-3 md:w-6" />
                    Twitter
                </a>
                <a
                    href="https://discord.com/invite/funsai"
                    target="_blank"
                    className=" flex items-center gap-x-1 md:gap-x-3 text-12-20  font-medium duration-300 hover:text-[#E9FF00]"
                >
                    <img src="/assets/discord.svg" alt="" className="w-3 md:w-6" />
                    Discord
                </a>
                <a
                    href="https://funs-ai.medium.com/"
                    target="_blank"
                    className=" flex items-center gap-x-1 md:gap-x-3 text-12-20  font-medium duration-300 hover:text-[#E9FF00]"
                >
                    <img src="/assets/medium.svg" alt="" className="w-3 md:w-6" />
                    Medium
                </a>

            </div>
            <p className="text-white text-center font-medium mt-2 text-sm">Powered by Knowpia Inc.</p>
            <p className="text-white text-center font-medium mt-2 text-sm"></p>
            <p className="text-white text-center font-medium  text-sm">3201 Skyway Ct, Fremont, CA 94539</p>
            <p className="text-white text-center font-medium  text-sm">medium@knowpia.com | admin@knowpia.com</p>
        </div>
    )
}

export default Footer
