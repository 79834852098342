const WhatIsFunsAI = () => {
    return (
        <div className="relative  md:h-fit" id="whatisfunsai">
            <video autoPlay muted loop src="/video/stars.mp4" className=" w-[100%] block md:hidden"></video>
            <video autoPlay muted loop src="/video/stars.mp4" className=" w-[100%]"></video>
            <div className="absolute inset-0">
                <div className="flex flex-col  md:flex-row h-full justify-center">
                    <div className=" md:flex-1 flex justify-center items-center">
                        {/* <div className="relative bg-[url(/public/assets/moon.svg)] bg-no-repeat bg-center"> */}
                        <div className="relative ">
                            <img src="/assets//globe.png" alt="" className="w-[200px] md:w-[569px]" />
                            <div className="absolute top-[30%]">
                                <img
                                    src="/assets/circles.svg"
                                    alt=""
                                    className="scale-x-[123%] translate-x-[-1%]"
                                />
                                <img
                                    src="/assets/nft.png"
                                    alt=""
                                    className="absolute w-[72px] -top-[10%] left-[50%]"
                                />
                                <img
                                    src="/assets/social.png"
                                    alt=""
                                    className="absolute w-[72px] top-[10%] left-[75%]"
                                />
                                <img
                                    src="/assets/music.png"
                                    alt=""
                                    className="absolute w-[72px] top-[40%] left-[90%]"
                                />
                                <img
                                    src="/assets/knowp.png"
                                    alt=""
                                    className="absolute w-[72px] top-[48%] left-[40%]"
                                />
                                <img
                                    src="/assets/hexagon.png"
                                    alt=""
                                    className="absolute w-[72px] top-[63%] left-[15%]"
                                />
                                <img
                                    src="/assets/presents.png"
                                    alt=""
                                    className="absolute w-[72px] top-[77%] -left-[5%]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" md:flex-1 flex justify-center items-center">
                        <div className=" md:w-[85%] text-center md:text-start">
                            <h1 className="font-extrabold text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                                What is Funs.AI?
                            </h1>
                            <p className="font-medium text-white text-12-20 mt-2 md:mt-5">
                                <span className="font-bold ">Funs.AI</span> is a SocialFi platform developed by a Silicon Valley-based startup Knowpia Inc., founded by a team with extensive experience and a successful track record. Our mission is to integrate AI and blockchain technology to create the platform that empowers users and creators. Currently, the team is developing the Funs.ai, which will offer an ecosystem combining social economy, digital asset trading, and e-commerce, with a beta launch planned in Q1, 2025.
                                <br/><br/>
                                <a
                href="https://forms.gle/qGgHLdrUKPNTXvYx7"
                target="_blank"
                className={`font-kanit font-medium text-14-16 text-black  py-1 md:py-2 px-3 md:px-5 bg-[#E9FF00] rounded-full`}
            >
                Pre-launch Invitation
            </a>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block absolute inset-0 top-[90%] z-50">
                <h1 className=" font-extrabold text-center text-[#E9FF00] text-[56px] text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                    OUR ECOSYSTEM
                </h1>
                <h3 className="cursor-pointer font-extrabold text-center text-[#E9FF00] text-16-32 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                        KNOWP Token, Security Token Offering (STO), The Base Network
                </h3>
                <p className="text-[25px] font-medium text-center text-white">
                providing participants with secure and compliant investment opportunities.
                </p>
            </div>
        </div>
    );
};

export default WhatIsFunsAI;